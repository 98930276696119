<template>
<!--  <div class="section" @click="$router.push('visitorCarList')">-->
<!--    <div class="round-box">-->
<!--      <div class="title">-->
<!--        <b class="fs16" style="color: #fafafa">{{ carNum }}</b>-->
<!--      </div>-->
<!--&lt;!&ndash;      <div class="subtitle">-->
<!--        车型 {{ brand }}-->
<!--      </div>&ndash;&gt;-->
<!--    </div>-->
<!--  </div>-->
  <VehicleReserveList2/>
</template>

<script>
//osp.car.VehicleReserve.getVehicleReserves


import VehicleReserveList2 from "../vehicle/VehicleReserveList2";

export default {
  components: {
    VehicleReserveList2
  },
  data() {
    return {
      carNum: '派车单',
      brand: '/',
      canReport: true
    };
  },
  created() {
   // this.getRecentCar();
  },

  methods: {
    //获取最近车辆
    getRecentCar() {
      var visitor = this.$sapi.storage.getItemJson('visitor');
      var visitorID = visitor.id;
      var option = {
        portName: 'osp.car.VehicleReserve.getVehicleReserves',
        data: {'visitor.id': visitorID},
        needLoading: false,
        pageIndex: 0,
        pageSize: 1,
        successCallback: (data) => {
          var entity = data.entities[0];
          if (!_.isEmpty(entity)) {
            this.carNum = entity['carNum'];
            this.brand = entity['brand'];
          }
        }
      }
      this.$sapi.callPort(option);
    },
  },
};
</script>

<style scoped>
.section {
  background: white;
}
.section + .section {
  margin-top: 10px;
}
.round-box {
  border-radius: 5px;
  background-color: #1989fa;
  padding: 10px 20px;
  margin: 10px 10px;
}
.round-box > .title {
  display: inline-flex;
  align-items: center;
}
.round-box > .subtitle {
  font-size: 12px;
  margin-top: 10px;
  color: #b6ffe7;
}
.tag {
  
}
.btn-group {
  display: flex;
}
.btn-group-item {
  flex: 1;
  text-align: center;
}
</style>