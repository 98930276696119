<template>
  <div style="background-color: #fafafa">
    <van-form @submit="onSubmit">
      <van-cell-group >

      <Picker
          name="dispatchVehicle.id"
          label="选择我关联的车辆"
          placeholder="选择我关联的车辆"
          displayField="carCodeHead"
          v-model:value="this.$data['dispatchVehicle.id']"
          :defConfig="{
               portName: 'tms.TmsVehicle.vapp.getMyDispatchCar',
           }"
      ></Picker>

          <van-button round block type="primary" native-type="submit">
            查询客户订单
          </van-button>
      </van-cell-group>
    </van-form>
    <!-- 上报-->
      <GridList title="车辆关联的客户订单" port-name="tms.transport.dispatchOrderVehicleCustom.getList"
                :request-data="{'vehicle.id': this.$data['dispatchVehicle.id'],'vapp':'vapp'}"
                :dataList="dataList" style="margin: 4.26667vw;"
                ref="grid">
        <ListCard
            v-for="item in dataList"
            :key="item.id"
            :onReport="onReport"
            :id="item.id"
            :arriveCode="item['arriveStatusDic.code']"

        >

           <div class="item" @click="onEdit(item['id'])">
   <!--          <van-image class="image" round  :src="require('../../assets/img/派车.png')"/>-->
            <div class="content">
              <div class="title fs16">{{ '客户订单： '+item['dispatchOrderCustomOrder.customOrder.name'] }}</div>
  <!--            <div class="tags fs12"><span style="color: #129d67">{{'派单状态: '+ item['dispatchOrder.dispatchOrderRfq.rfqStatusDic.name'] }}</span></div>-->
              <div class="time fs12">{{ '车辆运输状态： '+item['transportsStatusDic.name'] }}</div>
              <div class="time fs12">{{ '订单编号： '+item['dispatchOrderCustomOrder.customOrder.code'] }}</div>
              <div class="time fs12">{{ '起始地： '+item['nameStart'] }}</div>
              <div class="time fs12">{{ '目的地： '+item['nameEnd'] }}</div>
              <div class="time fs12">{{ '到达状态： '+item['arriveStatusDic.name'] }}</div>
  <!--            <div class="time fs12">{{ getDateStr(item['planDate'], item['startTime']) }}</div>-->
            </div>
            <div class="tagfs12">
             <van-tag v-for="it in item['nodeInfoList']" :key="it['nodeInfoName']" :type="getTagType(it['isExist'])">{{ it['nodeInfoName']}}</van-tag>

  <!--            <van-tag  plain type="primary"> {{ item['dispatchOrder.dispatchOrderRfq.id'] ? "已派单":"未派单" }}</van-tag>-->
  <!--            <van-tag plain type="warning"> {{ item['dispatchOrder.dispatchOrderRfq.id'] ? "已派单":"未派单" }}</van-tag>-->
            </div>
          </div>
        </ListCard>
      </GridList>

  </div>

</template>

<script>
import ListCard from "../../components/ListCard";
import _ from "lodash";
import Picker from "../../components/Picker";
import {Dialog} from "vant";
import GridList from "../../components/GridList";

export default {
  components: {Picker,ListCard, GridList},
  name: "VehicleReserveList2",
  data() {
    return {
      visitorID: '',
      dataList: [],
      canReport: true,
      "dispatchVehicle.id":'',
      lazyLoad:true,
    };
  },
  created: function () {
    var visitor = this.$sapi.storage.getItemJson('visitor');
    this.visitorID = visitor.id;
  },
  methods: {
    onAdd: function () {
      this.$router.push({name: 'vehicleReserve'})
    },
    onEdit: function (id) {
        this.$router.push({name: 'commonVehicleReserve', params: {id: id}})
    },
    onDel: function (id) {
      this.$dialog.confirm({
        title: '',
        message: '是否确定删除该记录？',
      })
          .then(() => {
            var option = {
              portName: 'osp.car.VehicleReserve.delVehicleReserve',
              data: {ids: [id]},
              needLoading: false,
              successCallback: () => {
                this.$notify({type: 'success', message: '删除成功'})
                this.$refs.grid.onRefresh();
              }
            }
            this.$sapi.callPort(option);
          })
          .catch(() => {

          });
    },
    onArrive: function (id,arriveCode) {
      if(arriveCode=== "LOAD_CAR_UNLOADING" || arriveCode=== "LOAD_CAR_UNLOADED"){
        return;
      }
      this.$dialog.confirm({
        title: '',
        message: '是否确定到达'+(arriveCode===null?'装车点':arriveCode==='LOAD_CAR_LOADED' || arriveCode==='LOAD_CAR_LOADING'?'卸车点':null),
      })
          .then(() => {
            var option = {
              portName: 'tms.transport.dispatchOrderVehicleMaterial.arrive',
              data: {ids: [id]},
              needLoading: false,
              successCallback: () => {
                this.$notify({type: 'success', message: '确认到达'})
                this.$refs.grid.onRefresh();
              }
            }
            this.$sapi.callPort(option);
          })
          .catch(() => {

          });
    },
    onRead: function (dispatchId) {
            var option = {
              portName: 'tms.transport.dispatch.getEntity',
              data: {id: dispatchId},
              needLoading: false,
              successCallback: (data) => {
                this.$router.push({name: 'wfLog', params: {id: data.entities[0].wfProcess}})
              }
            }
            this.$sapi.callPort(option);
    },
    onChange: function (id) {
      this.$dialog.confirm({
        title: '',
        message: '是否确定变更预约？',
      })
              .then(() => {
                var option = {
                  portName: 'osp.car.VehicleReserve.changeReserve',
                  data: {ids: [id]},
                  needLoading: false,
                  successCallback: () => {
                    this.$notify({type: 'success', message: '变更成功'});
                    this.$refs.grid.onRefresh();
                  }
                };
                this.$sapi.callPort(option);
              })
              .catch(() => {
              });
    },
    onReport: function (id) {
      this.$dialog.confirm({
        title: '',
        message: '是否确定变更预约？',
      })
          .then(() => {
            var option = {
              portName: 'osp.car.VehicleReserve.changeReserve',
              data: {ids: [id]},
              needLoading: false,
              successCallback: () => {
                this.$notify({type: 'success', message: '变更成功'});
                this.$refs.grid.onRefresh();
              }
            };
            this.$sapi.callPort(option);
          })
          .catch(() => {
          });
    },
    onSubmit() {
      this.$refs.grid.onRefresh();
    },
    getTagType(code) {
      switch (code) {
        case true:
          return 'success';
        case false:
          return 'warning ';
        default:
          return 'primary';
      }
    },
    getDateStr(d, t) {
      let date = _.isEmpty(d) ? "" : d.split(' ')[0];
      let time = _.isEmpty(t) ? "" : t.split(' ')[1];
      return date+ " " + time;
    },
  }
}

</script>

<style scoped>
.item {
  display: flex;
  border-radius: 5px;
  flex-direction: row;
  box-shadow: 0 0 2px #ddd;
  align-items: center;
  padding: 10px;
  margin: 2px;
}

.item + .item {
  margin-top: 10px;
}

.item > .image {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}
.title {
  display: block;
  text-decoration: none;
  line-height: 1.5;
  margin: 0px 0px;
  background-image: -webkit-linear-gradient(
      left,
      #4491e8,
      #b6549a,
      #b6549a
  );
  color: transparent;
  -webkit-background-clip: text;
  background-size: 200% 100%;
  animation: slide 5s infinite linear;
  font-size: 16px;
}

.item > .title {

}

.item .status_green {
  color: #519874;
}

.item .status_red {
  color: #f83817;
}

.item > .content {
  width: 500px;

}

.item > .tagfs12{
  width: 135px;
}

.item .time {
  color: #666;
}
</style>
